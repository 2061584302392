.AboutMeContents {
    display: flex;
    flex-direction: column;
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 1rem;
    .blurb {
      flex-direction: column;
      p {
        margin: 0;
      }
    }
    .icon-links {
      margin-top: 0.75rem;
    }
  }
  @media only screen and (min-width: 960px) {
    .AboutMeContents {
      margin-top: 0;
    }
  }

  #me {
    width: 100%;
    max-width: 15rem;
    height: fit-content;
    position: relative;
    padding: 30px;
    img {
      max-width: 100%;
      border-radius: 999px;
      display: block;
    }
  }
  #me::after {
    content: '';
    border-radius: 999px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: conic-gradient(
      from 30deg,
      var(--primary),
      var(--secondary) 46%,
      transparent 46%
    );
    -webkit-mask: radial-gradient(
      farthest-side,
      transparent calc(100% - 3px),
      #fff calc(100% - 3px + 1px)
    );
    mask: radial-gradient(
      farthest-side,
      transparent calc(100% - 3px),
      #fff calc(100% - 3px + 1px)
    );
  }
  @media only screen and (max-width: 1199px) {
    #AboutMe, #text-content {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      border-bottom: 3px solid var(--primary);
      padding-left: 0;
      margin-left: 1rem;
      h3 {
        margin-bottom: 0;
      }
    }
    #text-content {
      border: none;
    }
    #me {
      max-width: 30vw;
      margin-right: 0;
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
      border-width: 0 3px 0 0;
      border-style: solid;
      border-image: linear-gradient(to bottom, var(--secondary), var(--primary)) 1;
      img { 
        border-radius: 0;
      }
    }
    #me::after {
      display: none;
    }
  }
  
  .social-link {
    margin-right: .5em;
  }
  
  .social-link:hover {
    filter: brightness(1.75);
    transition: filter 300ms;
  }