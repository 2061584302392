#LandingPage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    gap: 0.5rem;
    margin-left: 4rem;
  } 
  
#LandingPage .Scroll-Arrow-Div {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10%;
  }

  .blurb.mobile {
    display: none;
  }

  @media only screen and (max-width: 960px) {
    #LandingPage {
      position: relative;
      justify-content: flex-start;
      margin-left: 4rem;
      margin-top: 12rem;
      height: 50vh;
      min-height: 400px;
      .Scroll-Arrow-Div {
        position: absolute;
        bottom: 1rem;
      }
    }
    .blurb.desktop {
      display: none;
    }
    .blurb.mobile {
      display: flex;
    }
}
  
#LandingPage .blurb, .blurb-cycle {
    font-size: 1.5rem;
    margin-bottom: 0;
    line-height: 2.5rem;
    margin: 0;
  }

div.blurb {
  display: flex;
  gap: 1rem;
  .fa-solid {
    line-height: 1.25;
    color: var(--text);
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    width: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  #LandingPage {
    margin-top: 6rem;
    margin-left: 0;
    width: 100%;
  }
}
  
.blurb-cycle {
    opacity: 0;
    animation: fade-in 1s ease-in forwards, fade-out 1s 4s ease-out forwards;
    p {
      margin: 0;
    }
  }
  
@keyframes fade-in {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }
  
@keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }