@import url('https://fonts.googleapis.com/css?family=Sora&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('./styles/typography.css');
@import url('./styles/nav.css');
@import url('./styles/landingPage.css');
@import url('./styles/aboutMe.css');
@import url('./styles/jobsAndEdu.css');
@import url('./styles/projects.css');
@import url('./styles/skillsBlock.css');

/* Screen break reference */
/* 
--xs: 0px;
--sm: 600px;
--md: 960px;
--lg: 1280px;
--xl: 1920px; 
*/

:root {
  /* --bg: #011627; */
  --bg: #031926;
  --text: #E2E2E2;
  --timberwolf: #D8D4D5;
  /* --secondary: #758ECD; */
  --secondary: #FDE74C;
  /* --secondary: #C9DDFF; */
  /* --secondary: #FFC4EB; */
  /* --secondary: #AFCBFF; */
  --primary: #00FDCF;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: var(--bg);
  color: var(--text);
}

.MuiGrid-container {
  padding: 2rem;
  max-width: 90rem;
  margin: auto;
  .MuiGrid-container {
    padding: 0;
    .MuiGrid-item {
      padding: 0;
    }
  }
}

.MuiGrid-container.app {
  font-family: 'Lato';
  margin-bottom: 4rem;
}

dd {
  display: list-item;
  list-style-type: "‣";
  padding-inline-start: 1em;
  font-size: 0.9em;
  font-weight: 300;
  margin-left: 0.75rem;
  line-height: 1.5rem;
}

dl {
  margin-bottom: 0;
}

.page-container {
  background-color: var(--bg);
  padding-left: 6rem;
} 

/* Provide padding and margins for anchor tags */
div#Skills, div#Projects, #AboutMe, div#ProfExperience, div#Education {
  padding-top: 4rem;
  margin-top: 6rem;
}

#AboutMe{
  margin-right: 2rem;
}

p.last-update {
  opacity: 0.75;
  text-align: right;
  font-size: 0.8rem;
}

.remove-padding {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .page-container {
    padding-left: 0;
  }
  #AboutMe{
    margin-right: 0;
  }
  div#Skills, div#Projects, #AboutMe, div#ProfExperience, div#Education {
    padding-top: 8rem;
    margin-top: 0;
  }
}

@media only screen and (max-width: 600px) {
  #text-content, #Skills, #last-updated {
    padding-right: 2rem;
  }
  #text-content {
    padding-left: 0;
    padding-top: 0.25rem;
  }
  .MuiGrid-container {
    padding-right: 0;
    .MuiGrid-container {
      padding-left: 0;
    }
  }
  #AboutMe, #LandingPage{
    margin-right: 2rem;
  }
  #LandingPage {
    padding-top: 4rem;
  }
  div#Skills, div#Projects, #AboutMe, div#ProfExperience, div#Education {
    margin-top: 2rem;
  }
}