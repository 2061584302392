.skills-nav {
    display: flex;
    background-color: var(--bg);
    justify-content: left;
    padding-top: 2rem;
}

.skills-nav .box {
    height: 1rem;
    width: 1rem;
    background-color: var(--bg);
}

.skills-nav .box.right .circle {
    height: 1rem;
    width: 1rem;
    border-radius: 0 0 0 6px;
    background-color: var(--bg);
}

.skills-nav .box.left .circle {
    height: 1rem;
    width: 1rem;
    border-radius: 0 0 6px 0;
    background-color: var(--bg);
}

.nav-plus-box {
    display: flex;
    align-items: end;
    position: relative;
}

.nav-plus-box h5 {
    margin: 0;
}

.nav-plus-box .nav-item {
    background-color: var(--bg);
    color: var(--platinum);
    padding: 0.5rem 1rem;
    border-radius: 4px 4px 0 0;
    display: flex;
    margin: 0;
    cursor: pointer;
}

.nav-plus-box:hover {
    .nav-item {
        background-color: rgba(255, 255, 255, 0.2);
        color: white;
    }
    .box {
        background-color: rgba(255, 255, 255, 0.2);
    }
    .radius-filler {
        height: 1rem;
        width: 1rem;
        background-color: rgba(255, 255, 255, 0.25);
        position: absolute;
        bottom: -1rem;
    }
}
.nav-plus-box.active, .nav-plug-box.active:hover {
    .nav-item {
        background-color: white;
        color: var(--bg);
    }
    .box {
        background-color: white;
    }
}
@media only screen and (max-width: 600px) {
    .skills-nav {
        width: 100%;
        justify-content: space-between;
        text-align: center;
    }
    .nav-plus-box.far-right {
        .box.right {
            display: none;
        }
        &.active {
            .radius-filler {
                right: 0;
                background-color: white;
            }
        }
    }
    .nav-item h5 {
        font-size: 0.8rem;
    }
}

.skills-container {
    background-color: white;
    padding: 2rem;
    border-radius: 0 4px 4px 4px;
    &.top-left-radius {
        border-radius: 4px;
    }
    .skill-badge-container {
        height: auto;
        width: auto;
        margin: 0.75em;
        display: flex;
        align-items: center;
    }
    img.skill-badge {
        width: auto;
        height: auto;
        max-width: 12.5rem;
        max-height: 4.5rem;
      }
}

/* Skills list */
.skills-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1rem 0 1.5rem 0;
    width: 100%;
    justify-content: center;
    .skill-list-item {
        font-size: 0.8rem;
        font-weight: 400;
        padding: 0.25rem 0.75rem;
    }
}


/* Hide / show the skills correlated to the nav  */
div.technical-skills-container,
div.PM-skills-container,
div.ME-skills-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 1000px;
    overflow: hidden;
    &.hide {
        max-height: 0;
    }
    &.hide img {
        display: none;
    }
}