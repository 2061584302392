.jobs {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    p {
      margin-bottom: 0.25rem;
    }
  }
  
  .edu-contents {
    display: flex;
    gap: 1.5rem;
    padding: 2rem;
    margin-top: 0.5rem;
  }

  @media only screen and (max-width: 600px) {
    .jobs .MuiGrid-container {
      padding-right: 0;
    }
  }

  .MuiGrid-container .MuiGrid-container.job-block {
    display: flex;
    gap: 1.5rem;
    padding: 2rem;
    padding-bottom: 0;
    margin-top: 0.5rem;
  }

  @media only screen and (max-width: 600px) { 
    .edu-contents{
      padding-left: 0;
    }
    .MuiGrid-container .MuiGrid-container.job-block {
      padding-left: 1rem;
    }
    .jobs {
      gap: 1rem;
    }
  }
  
  a.employeer p:hover, a.institution p:hover {
    text-decoration: underline;
  }
  
  .job {
    display: flex;
    flex-direction: column;
  }

  .job-header {
    display: flex;
    justify-content: flex-start;
  }

  .edu-header {
    display: flex;
    flex-direction: column;
    a, p {
      margin-bottom: 0.5rem;
    }
  }

  .years {
    font-size: 0.8rem;
  }
  
  .position {
    margin-right: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.25em;
  }
  
  .employeer {
    display: flex;
    flex-direction: column;
    font-size: 1.25em;
    justify-content: center;
    margin-right: 1em;
    margin-left: 1em;
    margin-bottom: 0.5rem;
  }
  
  a.employeer, a.institution  {
    text-decoration: none;
    color: var(--secondary);
  }

  a.institution {
    margin: 0;
    p {
      margin-right: 0.5rem;
      font-size: 1.25rem;
      margin-bottom: 0.2rem;
    }
  }

  p.program {
    font-size: 1.15rem;
  }
  
  .employer a:hover {
    filter: brightness(2);
    transition: filter 300ms;
  }
  
  .job-skills {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 1em;
    width: 100%;
  }

 .logo-container img {
    max-width: 4rem;
    height: auto;
    object-fit: contain;
    background-color: white;
}

p.edu-blurb {
  gap: 0;
  margin-bottom: 0.25rem; 
}

.education {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .education {
    flex-direction: column;
  }
}