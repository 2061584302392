.header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    position: sticky;
    top: 4rem;
    margin-top: 1rem;
  }
  
.home-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
  }
  
.home-btn:hover {
    filter: brightness(1.75);
    transition: filter 500ms;
  }
  
.home-btn a.name {
    font-family: 'Sora';
    font-size: 1.5em;
    color: var(--secondary);
    margin: 0;
    position: relative;
    white-space: nowrap;
    text-align: right;
  }

.navbar-container {
    display: flex;
    flex-direction: column;
    font-size: 1em;
    margin-top: 10%;
  }
  
.navbar-container a {
    border: none;
    text-align: right;
    color: var(--text);
    font-weight: 300;
  }
  
.navbar-container p.navlink-highlight {
    color: var(--primary);
    font-weight: bold;
  }

  .navlink:hover {
    color: var(--secondary);
    filter: brightness(1.25);
    svg path {
      stroke: var(--secondary);
    }
  }
  .navlink:focus {
    svg path {
      stroke: var(--text);
    }
  }

  @media only screen and (max-width: 1199px) {
    .navlink:focus {
      svg path {
        stroke: var(--bg);
      }
    }
  }

/* Mobile Nav */
.off-canvas-nav {
  position: fixed;
  top: 0;
  height: fit-content;
  width: 100%;
  color: var(--bg);
  transition: left 0.3s ease;
  z-index: 2;
}

.menu-toggle {
  cursor: pointer;
  color: var(--bg);
  font-size: 24px;
}

.mobile-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  a {
    margin: 0;
    &.name {
      font-size: 1.5rem;
      font-weight: 400;
      color: var(--bg);
      font-family: 'Sora';
    }
  }
}
.mobile-navbar-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  padding-left: 2rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  .navlink {
    color: var(--bg);
    text-align: right;
    font-size: 1.5rem;
    font-family: 'Lato';
  }
}
nav {
  transition: right 0.5s ease;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
}

nav.open {
  right: 0;
}

div.resume {
  display: flex;
  justify-content: flex-end;
  svg {
    height: 1.25rem;
    margin-left: 0.25rem;
  }
}

@media only screen and (max-width: 600px) {
  nav {
    right: -250px;
    width: 35%;
  }
}
@media only screen and (max-width: 1199px) {
  nav {
    right: -400px;
    max-width: 50%;
  }
  .resume svg {
    height: 1.75rem !important;
    path {
      stroke: var(--bg);
    }
  }
}
